@import './font/index.css';
.App {
  /* text-align: center; */
  width: 100vw;
}
body {
  width: 100vw;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.xinxi .ant-input::placeholder {
  color: #656565 !important;
}
.loginModule .ant-input::placeholder {
  color: #fff !important;
}
.interviewDetil .ant-tabs-tab{
  width: 140px;
  margin-left: 0 !important;
}
.interviewDetil .ant-tabs-tab-btn {
  width: 100%;
  text-align: center;
}
.interviewDetil .ant-tabs-nav {
  margin-bottom: 0;
}